const MenuUser = [
  {
    heading: "Mon compte AWS",
    translate: "sidebar.heading.MY_ACCOUNT",
  },
  {
    name: "Gérer mon compte",
    translate: "sidebar.nav.USER_SETTINGS",
    path: "userSettings",
  },
]

export default MenuUser
