import { v4 as uuidv4 } from 'uuid';
import { initDb } from "../tools/conf";
import { generateTaxonomyList } from '../tools/dataConverter';


export class akongoGlobalDatabaseConnector {
    async _getSpeciesList() {
        let db = await initDb()
        let list = []
        try {
            let snapshot = await db.collection("species").orderBy("specieLatinName").get()

            if (snapshot.empty) {
            } else {

                snapshot.forEach(doc => {
                    let specieData = doc.data()
                    list.push({
                        label: specieData.specieName + ' ' + specieData.specieLatinName,
                        value: specieData.specieId,
                        ...specieData
                    })
                });
            }

            list.sort(function (a, b) {
                if (a.specieName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.specieName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) { return -1; }
                if (a.specieName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") > b.specieName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) { return 1; }
                return 0;
            })

            return list
        } catch {
            console.log(console.error())
        }
    }

    async _getTaxonomy(specieId) {
        let db = initDb()
        try {
            let snapshot = await db.collection("species").doc(specieId).get()
            return snapshot.data()
        } catch (error) {
            console.log(error)
        }
    }

    async _getTaxonomyFromLatin(latinName) {
        let db = initDb()
        try {
            let snapshot = await db.collection("species").where("specieLatinName", "==", latinName).get()
            let list = []
            if (snapshot.empty) {
                return null
            }
            else {
                snapshot.forEach(doc => {
                    list.push(doc.data())
                });
                return list[0]
            }

        } catch (error) {
            console.log(error)
        }
    }

    async _getTaxonomyList() {
        let remoteData = await Promise.all([
            await this._getSpeciesList(),
            await this._getOrderList(),
            await this._getFamilyList()
        ])

        let taxList = generateTaxonomyList(remoteData[0], remoteData[1], remoteData[2])

        return taxList
    }

    async _addSpecieInGlobalList(newSpecieData) {
        try {
            let db = await initDb()
            let uuid = uuidv4()
            let familyId = null
            let orderId = null
            try {
                familyId = newSpecieData.specieFamily.familyId
                orderId = newSpecieData.specieFamily.familyOrder.orderId
            } catch (error) {
                console.log(error)
            }

            let newEntry = {
                specieId: uuid,
                familyId: familyId,
                orderId: orderId,
                ...newSpecieData
            }
            await db.collection('species').doc(uuid).set(newEntry)
            return { success: true, data: newEntry }
        } catch (error) {
            console.log(error)
            return { success: false, data: error }
        }
    }

    async _modifySpecieFromGlobalList(newSpecieData) {
        let db = await initDb()
        let familyId = null
        let orderId = null
        try {
            familyId = newSpecieData.specieFamily.familyId
            orderId = newSpecieData.specieFamily.familyOrder.orderId
        } catch (error) {

        }
        let newEntry = {
            familyId: familyId,
            orderId: orderId,
            ...newSpecieData
        }
        try {
            db.collection("species").doc(newSpecieData.specieId).update(newEntry)
            return { success: true, data: newEntry }
        } catch (error) {
            console.log("newSpecieData")
            console.log(newSpecieData)

            console.log("error")
            console.log(error)

            return { success: false, data: error }
        }
    }

    async _removeSpecieFromGobalList(specieId) {
        try {
            let db = await initDb()
            await db.collection('species').doc(specieId).delete()
            return { success: true }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    // family

    async _getFamilyList() {
        let db = await initDb()
        let list = []
        try {
            let snapshot = await db.collection("family").get()
            if (snapshot.empty) {
            } else {
                snapshot.forEach(doc => {
                    let familyData = doc.data()
                    list.push({
                        label: familyData.familyName + ' - ' + familyData.familyLatinName,
                        value: familyData.familyId,
                        ...familyData
                    })
                });
            }
            return list
        } catch {
            console.log(console.error())
        }
    }

    async _addFamilyInGlobalList(newFamilyData) {
        try {
            let db = await initDb()
            let uuid = uuidv4()
            let newEntry = {
                familyId: uuid,
                ...newFamilyData
            }
            await db.collection('family').doc(uuid).set(newEntry)
            return { success: true, data: newEntry }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    async _modifyFamilyFromGlobalList(newFamilyData) {
        let db = await initDb()
        let newEntry = {
            ...newFamilyData
        }
        try {
            db.collection("family").doc(newFamilyData.familyId).update(newEntry)
            return { success: true, data: newEntry }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    async _removeFamilyFromGobalList(familyId) {
        try {
            let db = await initDb()
            await db.collection('family').doc(familyId).delete()
            return { success: true }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    // family

    async _getOrderList() {
        let db = await initDb()
        let list = []
        try {
            let snapshot = await db.collection("order").get()
            if (snapshot.empty) {
            } else {
                snapshot.forEach(doc => {
                    let orderData = doc.data()

                    list.push({
                        label: orderData.orderName + ' - ' + orderData.orderLatinName,
                        value: orderData.orderId,
                        ...orderData
                    })
                });
            }
            return list
        } catch {
            console.log(console.error())
        }
    }

    async _addOrderInGlobalList(newOrderData) {
        try {
            let db = await initDb()
            let uuid = uuidv4()
            let newEntry = {
                orderId: uuid,
                ...newOrderData
            }
            await db.collection('order').doc(uuid).set(newEntry)
            return { success: true, data: newEntry }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    async _modifyOrderFromGlobalList(newOrderData) {
        let db = await initDb()
        let newEntry = {
            ...newOrderData
        }
        try {
            db.collection("order").doc(newOrderData.orderId).update(newEntry)
            return { success: true, data: newEntry }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    async _removeOrderFromGobalList(orderId) {
        try {
            let db = await initDb()
            await db.collection('order').doc(orderId).delete()
            return { success: true }
        } catch (error) {
            return { success: false, data: error }
        }
    }

    async _getSurveyAmountFromSpecieId(specieId, allQuizz) {
        let amount = 0
        let prod = 0
        allQuizz.map(el => {
            console.log(el)
            try {
                if (el.surveySpecie.specieId === specieId) {
                    amount = amount + 1
                    prod = prod + el.activeCampaigns.length
                }
            } catch (error) {

            }

        })


        return {
            amount: amount,
            prod: prod
        }
    }

    async _getObsAmountFromSpecieId(specieId, allObs) {
        let amount = 0
        allObs.map(el => {
            console.log(el)
            try {
                el.obsSpecie.map(el => {
                    if (el.specieId === specieId) {
                        amount = amount + 1
                    }
                })

            } catch (error) {

            }

        })


        return amount
    }

    // Short management


    async _getShortQuestionsTable() {
        let db = initDb()
        let shortQuestionTable = (await db.collection("various").doc("shortQuestionTable").get()).data()
        return shortQuestionTable
    }

    // Version Management


    async _addEntryToSurveyLog(newEntry) {
        try {
            let db = initDb()
            let res = await db.collection("species").doc(newEntry.specieId).collection("logs").doc(newEntry.timestamp.toString()).set(newEntry)
            return null
        } catch (error) {
            return null
        }
    }

    async _setSpecieUnderReview(specieId, reviewData) {
        try {
            let db = initDb()
            await db.collection("species").doc(specieId).update({
                underReview: true,
                reviewData: reviewData
            })
            return {
                success: true
            }
        } catch (error) {

            return {
                success: false
            }
        }



    }

    async _getSurveyLogs(specieId, limit) {
        try {
            let db = initDb()
            let snap = await db.collection("species").doc(specieId).collection("logs").orderBy("timestamp", "desc").limit(limit).get()
            let data = []
            snap.forEach(el => {
                data.push(el.data())
            })
            return data
        } catch (error) {
            console.log(error)
            return []
        }
    }

}


