const MenuAdmin = [
  {
    heading: "Akongo Welfare Manager",
  },

  {
    name: "Centre de pilotage",
    path: "DashboardAkongo",
    translate: "sidebar.nav.DASHBOARD",
  },
  {
    name: "Historiques des évaluations",
    path: "EvalHistory",
    translate: "sidebar.nav.HISTORY",
  },
  {
    name: "Bilan d'Activité",
    path: "cirManagementView",
  },
  {
    name: "Catalogue des espèces",
    path: "DashboardSpecie",
    translate: "sidebar.nav.SPECIES_CATALOGUE",
  },
  {
    heading: "Gestion du parc",
    translate: "sidebar.heading.PARC_MANAGEMENT",
  },

  {
    name: "Gestion des campagnes",
    path: "CampaignListView",
    translate: "sidebar.nav.CAMPAIGN_MANAGEMENT",
  },
  {
    name: "Gestion des individus",
    translate: "sidebar.nav.ANIMAL_MANAGEMENT",
    path: "specieAnimalsListView",
  },
  {
    name: "Gestion des groupes",
    translate: "sidebar.nav.GROUP_MANAGEMENT",
    path: "GroupListView",
  },
  {
    name: "Gestion des enclos",
    path: "EnclosureListView",
    translate: "sidebar.nav.ENCLOSURE_MANAGEMENT",
  },
  {
    name: "Gestion des enrichissements",
    path: "EnrichListView",
    translate: "sidebar.nav.ENRICHMENT_MANAGEMENT",
  },
  {
    name: "Consulter les évaluations",
    path: "CampaignQuizzMonitoringView",
    translate: "sidebar.nav.EVALUATION_MONITORING",
  },
  {
    name: "Consulter les questionnaires",
    path: "QuizzListViewReadOnly",
    translate: "sidebar.nav.QUIZZ_MONITORING",
  },

  {
    heading: "Mon compte AWS",
    translate: "sidebar.heading.MY_ACCOUNT",
  },
  {
    name: "Gérer mon compte",
    translate: "sidebar.nav.USER_SETTINGS",
    path: "userSettings",
  },
  {
    name: "Inviter des utilisateurs",
    translate: "sidebar.nav.USER_CREATION",
    path: "userCreation",
  },

  {
    heading: "Administration AWS",
    translate: "sidebar.heading.ADMINISTRATION",
  },
  {
    name: "Gestion des établissements",
    translate: "sidebar.nav.ESTABLISHMENT_MANAGEMENT",
    path: "ZooList",
  },
  {
    name: "Projets de recherche",
    translate: "sidebar.nav.PROJECT_MANAGEMENT",
    path: "ResearchListView",
  },

  {
    name: "Gestion questionnaires",
    translate: "sidebar.nav.GESTION_QUESTIONNAIRES",
    submenu: [
      {
        name: "Gestion Questionnaires",
        translate: "sidebar.nav.GESTION_QUESTIONNAIRES_VIEW",
        path: "quizzListView3",
      },

      {
        name: "Gestion Questions",
        translate: "sidebar.nav.GESTION_QUESTIONS_VIEW",
        path: "QuestionListView",
      },
      {
        name: "Gestion Profils",
        translate: "sidebar.nav.GESTION_PROFILS_VIEW",
        path: "QuizzProfileView",
      },
      {
        name: "(old) Questionnaires",
        translate: "sidebar.nav.GESTION_QUESTIONNAIRES_VIEW_OLD",
        path: "quizzListView2",
      },
    ],
  },
  {
    name: "Gestion des observations",
    translate: "sidebar.nav.OBSERVATION_MANAGEMENT",
    submenu: [
      // {
      //   name: "Séquences d'obs",
      //   translate: "sidebar.nav.SUBMENU",
      //   path: "ObsSessionListView",
      // },
      {
        name: "Ethogrammes",
        translate: "sidebar.nav.ETHOGRAMME",
        path: "ObsListView",
      },
      {
        name: "Contextes",
        translate: "sidebar.nav.CONTEXTE",
        path: "ObsContextListView",
      },
    ],
  },

  {
    name: "Gestion des taxons",
    translate: "sidebar.nav.TAXON_MANAGEMENT",
    submenu: [
      {
        name: "Base ordres",
        path: "/OrderListManagement",
        translate: "sidebar.nav.ORDER_MANAGEMENT",
      },
      {
        name: "Base familles",
        path: "/FamilyListManagement",
        translate: "sidebar.nav.FAMILY_MANAGEMENT",
      },
      {
        name: "Base espèces",
        path: "/SpecieListManagement",
        translate: "sidebar.nav.ESPECE_MANAGEMENT",
      },
    ],
  },
]

export default MenuAdmin
