export const surveyTable = [{
    label: "Questionnaire 001",
    value: "ID1",
    groupSurvey: true
}]

export const surveys = {
    "ID1": {
        surveyData: {
            surveyName: "Questionnaire 001"
        },
        survey:
            [
                /// Question 1 
                {
                    questionTitle: "Question 1",
                    questionSubtitle: "blobloblo",
                    questionIllustration: "https://www.grincant.com/wp-content/uploads/2018/12/petit-poney.jpg",
                    questionId: "001",
                    questionCategory: "behavior",
                    category: 1,
                    options: [{
                        Text: "TRES BIEN",
                        points: 0,
                        isFatal: false,
                    }, {
                        Text: "BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "PAS TRES BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "PAS BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "nul",
                        points: 0,
                        isFatal: false,
                    },
                    ]
                },
                // Question 2
                {
                    questionTitle: "Blobloblo",
                    questionSubtitle: "002",
                    questionCategory: "behavior",
                    help: {
                        title: "Message d'aide",
                        text: "pas mal hein?"
                    },
                    category: 1,
                    options: [{
                        Text: "TRES BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "PAS TRES BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "PAS BIEN",
                        points: 0,
                        isFatal: true,
                    }, {
                        Text: "nul",
                        points: 0,
                        isFatal: false,
                    },
                    ]
                },
                // Question 3
                {
                    questionTitle: "Accessibilité à l'eau",
                    questionSubtitle: "Les animaux ont-ils accès en permanence à l'eau fraiche ?",
                    questionIllustration: "",
                    questionId: "003",
                    questionCategory: "Food",
                    category: 3,
                    help: {
                        title: "Aide",
                        text: "Vérifier l'accès à l'eau lors des observations et demander à l'équipe s'il y a restriction ou contrôle hydrique"
                    },
                    options: [{
                        Text: "Restriction hydrique",
                        points: 0,
                        isFatal: false,
                    }, {
                        Text: "Contrôle hydrique SANS accès à l'eau lors du nourrissage",
                        points: 1,
                        isFatal: false,
                    }, {
                        Text: "Contrôle hydrique AVEC accès à l'eau lors du nourrissage",
                        points: 2,
                        isFatal: false,
                    }, {
                        Text: "Ad libitum - Biberon",
                        points: 3,
                        isFatal: false,
                    },
                    {
                        Text: "Ad libitum - Système automatique",
                        points: 4,
                        isFatal: false,
                    },
                    ]
                },
            ]

    }
}





export function getCategoryNameFromCategoryId(id) {
    let res = ""

    QUIZZCATEGORIELIST.forEach(item => {
        if (item.categoryId === id) {
            res = item.categoryName
        }
    })
    if (res === "") {
        return "inconnu"
    } else {
        return res
    }
}

export const QUIZZCATEGORIELIST = [
    { categoryId: 1, categoryName: "Hébergement" },
    { categoryId: 2, categoryName: "Alimentation" },
    { categoryId: 3, categoryName: "Santé" },
    { categoryId: 4, categoryName: "Comportement" },
]

